import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/shared/Layout"
import PageHeader from "../components/shared/PageHeader"
import CareersContent from "../components/careers/CareersContent"

const CareersPage = ({ data }) => {
  return (
    <Layout
      seoTitle="Careers"
      seoKeywords="Fresh Graduate, Career, Job, Engineering Career, Professional, Experienced, JFEM Job Openings, Engineering Job Openings, JFE Engineering Malaysia Job Openings, JFE Engineering Malaysia Hiring, Life at JFEM, JFEM Job Application"
    >
      <PageHeader
        pageTitle="Careers"
        pageDescription={data?.markdownRemark?.frontmatter?.header?.description}
      />
      <CareersContent />
    </Layout>
  )
}

export default CareersPage

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "careers" } }) {
      frontmatter {
        header {
          description
        }
      }
    }
  }
`
