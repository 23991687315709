import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import JobApplicationForm from "./JobApplicationForm"
import Testimonial from "./Testimonial"
import CircleBg from "../../../static/svg/Large-Circle.svg"
import CircleBgMedium from "../../../static/svg/Medium-Circle.svg"
import { GradientFooterBg } from "../shared/Footer"
import {
  StyledContainer,
  DropYourResume,
  StyledCol,
  StyledRow,
} from "./CareersContent.styled"
import {
  Heading32,
  Heading40,
  Paragraph18,
  StyledButton,
} from "../../styles/PageStyles"

const CareersContent = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "careers" } }) {
        frontmatter {
          joinUs {
            hidden
            title
            professional {
              hidden
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            freshGraduate {
              hidden
              title
              description
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          dropYourResumeBg {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  `)

  const { frontmatter } = data?.markdownRemark

  return (
    <StyledContainer fluid>
      <div id="join-us">
        {!frontmatter.joinUs?.hidden && (
          <>
            <Heading40 data-aos="zoom-out">
              {frontmatter.joinUs?.title}
            </Heading40>
            {!frontmatter?.joinUs?.professional?.hidden && (
              <StyledRow
                id="professional"
                className="professional-wrapper"
                reversed
              >
                <StyledCol
                  className="career-desc-col"
                  lg={6}
                  data-aos="fade-right"
                >
                  <Heading32>
                    {frontmatter?.joinUs?.professional?.title}
                  </Heading32>
                  <Paragraph18>
                    <ReactMarkdown>
                      {frontmatter?.joinUs?.professional?.description}
                    </ReactMarkdown>
                  </Paragraph18>
                  <StyledButton to="/careers/professional/">
                    <Paragraph18>View Open Positions</Paragraph18>
                  </StyledButton>
                </StyledCol>
                <StyledCol className="img-col" lg={6} data-aos="fade-left">
                  <GatsbyImage
                    className="professional-img"
                    image={getImage(frontmatter?.joinUs?.professional?.image)}
                    alt={frontmatter?.joinUs?.professional?.title}
                    layout="fullWidth"
                  />
                </StyledCol>
              </StyledRow>
            )}
            {!frontmatter?.joinUs?.freshGraduate?.hidden && (
              <StyledRow id="fresh-grad" className="fresh-grad-wrapper">
                <StyledCol
                  className="img-col"
                  lg={6}
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <GatsbyImage
                    className="fresh-grad-img"
                    image={getImage(frontmatter?.joinUs?.freshGraduate?.image)}
                    alt={frontmatter?.joinUs?.freshGraduate?.title}
                    layout="fullWidth"
                  />
                </StyledCol>
                <StyledCol
                  className="career-desc-col"
                  lg={6}
                  data-aos="fade-left"
                  data-aos-delay="200"
                >
                  <Heading32>
                    {frontmatter?.joinUs?.freshGraduate?.title}
                  </Heading32>
                  <Paragraph18>
                    <ReactMarkdown>
                      {frontmatter?.joinUs?.freshGraduate?.description}
                    </ReactMarkdown>
                  </Paragraph18>
                  <StyledButton to="/careers/fresh-graduate/">
                    <Paragraph18>View Open Positions</Paragraph18>
                  </StyledButton>
                </StyledCol>
              </StyledRow>
            )}
          </>
        )}
      </div>
      <CircleBg className="circle-bg" />
      <CircleBgMedium className="circle-bg-medium" />
      <div id="life-at-jfeem">
        <Testimonial />
      </div>
      <DropYourResume id="drop-your-resume">
        <GatsbyImage
          className="form-bg"
          image={getImage(frontmatter?.dropYourResumeBg)}
          alt="Drop your resume"
        />
        <JobApplicationForm show={true} />
      </DropYourResume>
      <GradientFooterBg marginLeft marginLeftTablet />
    </StyledContainer>
  )
}

export default CareersContent
