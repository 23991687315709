import React from "react"
import styled from "styled-components"
import { MdClose } from "react-icons/md"
import { Heading24, Paragraph18 } from "../../styles/PageStyles"

const TermsAndCondition = ({ show, setShowModal, setCheck }) => {
  const handleDecline = (e) => {
    e.preventDefault()
    setCheck(false)
    setShowModal(false)
  }

  const handleAccept = (e) => {
    e.preventDefault()
    setCheck(true)
    setShowModal(false)
  }

  return (
    <>
      {show === true && (
        <Container>
          <Modal>
            <MdClose
              className="close-btn"
              size={30}
              onClick={() => setShowModal((prev) => !prev)}
            />
            <Heading24 className="title">Terms and Condition</Heading24>
            <Paragraph18>
              I have accepted this website's privacy policy and have given my
              consent regarding my personal data.
            </Paragraph18>
            <div className="buttons">
              <button className="decline" onClick={handleDecline}>
                Decline
              </button>
              <button className="accept" onClick={handleAccept}>
                Accept
              </button>
            </div>
          </Modal>
        </Container>
      )}
    </>
  )
}

export default TermsAndCondition

const Container = styled.div`
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 426px) {
    position: fixed;
    border-radius: 0;
  }
`

const Modal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  width: 70%;
  height: 70%;
  padding: 40px;

  .title {
    font-weight: bold;
    text-align: center !important;
    margin-bottom: 35px;
  }

  p {
    letter-spacing: 0.06em;
    color: #5b5d5e;
    margin-bottom: 35px;
  }

  .buttons {
    display: flex;
    justify-content: center;

    button {
      all: unset;
      margin: 0 7.5px;
      padding: 10px 30px;
      color: #fff;
      cursor: pointer;
    }

    .decline {
      background: #f46c6c;
      border-radius: 20px;
    }

    .accept {
      background: #0073af;
      border-radius: 20px;
    }
  }

  @media only screen and (max-width: 768px) {
    min-height: 430px;
  }

  @media only screen and (max-width: 482px) {
    width: 80%;
    height: 60%;
    .buttons {
      flex-wrap: wrap;

      .decline {
        margin-bottom: 25px;
      }
    }
  }
`
