import React, { useState } from "react"
import { Form, Button, Toast } from "react-bootstrap"
import ReCAPTCHA from "react-google-recaptcha"
import { MdClose } from "react-icons/md"
import TermsAndConditions from "./TermsAndConditions"
import { Heading24, Paragraph16 } from "../../styles/PageStyles"
import { StyledToast, StyledForm } from "./JobApplicationForm.styled"

export const FormNotification = ({ show, setShow }) => {
  return (
    <StyledToast show={show}>
      <Toast.Header closeButton={false}>
        <Paragraph16 className="me-auto">Form Notification</Paragraph16>
        <button
          type="button"
          class="btn-close btn-close-white"
          onClick={() => setShow(false)}
          aria-label="Close"
          data-dismiss="toast"
        ></button>
      </Toast.Header>
      <Toast.Body>Thank you! Your resume has been sent.</Toast.Body>
    </StyledToast>
  )
}

const JobApplicationForm = ({ modal, show, setShowModal }) => {
  const [file, setFile] = useState({
    attachment: "Max size: 2mb",
    invalid: false,
  })
  const [fileKey, setFileKey] = useState(0)
  const [formKey, setFormKey] = useState(0)
  const [validated, setValidated] = useState(false)
  const [recaptcha, setRecaptcha] = useState(false)
  const [recaptchaStyle, setRecaptchaStyle] = useState({
    border: "none",
  })
  const [formData, setFormData] = useState({
    subject: "[Applicant] You have new form submission",
    name: "",
    email: "",
    contactNumber: "",
    fileUpload: "",
  })
  const [showTerms, setShowTerms] = useState(false)
  const [check, setCheck] = useState(false)
  const [showNotification, setShowNotification] = useState(false)

  function encode(data) {
    const formData = new FormData()
    for (const key of Object.keys(data)) {
      if (key === "fileUpload") {
        formData.append(key, data[key][0])
      } else {
        formData.append(key, data[key])
      }
    }
    return formData
  }

  const formPost = () => {
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": "Job Applications v1",
        ...formData,
      }),
    }).catch((error) => alert(error))

    setShowNotification(true)
    if (!showNotification) {
      setTimeout(() => {
        setShowNotification(false)
      }, 5000)
    }
  }

  const handleChange = (event) => {
    const newFormData = { ...formData }
    newFormData[event.target.id] = event.target.value
    setFormData(newFormData)
  }

  const handleNameChange = (event) => {
    const re = /^[a-zA-Z\s]*$/
    if (event.target.value === "" || re.test(event.target.value)) {
      const newFormData = { ...formData }
      newFormData[event.target.id] = event.target.value
      setFormData(newFormData)
    }
  }

  const handleFileChange = (e) => {
    const allowedFileExtensions = /(\.pdf|\.doc|.docx)$/i
    try {
      if (e.target.files[0].size > 2097152) {
        setFileKey(fileKey + 1)
        setFile({
          ...file,
          invalid: true,
          attachment: "Failed to upload file. Max size: 2mb",
        })
      } else if (!allowedFileExtensions.exec(e.target.files[0].name)) {
        setFileKey(fileKey + 1)
        setFile({
          ...file,
          invalid: true,
          attachment:
            "Failed to upload file. File must be PDF or Word document.",
        })
      } else {
        setFile({
          ...file,
          invalid: false,
          attachment: e.target.files[0].name,
        })
        setFormData({
          ...formData,
          fileUpload: e.target.files,
        })
      }
    } catch (error) {
      setFile({
        ...file,
        size: null,
        attachment: "Max size: 2mb",
      })
    }
  }

  const reCaptchaStyle = {
    border: recaptchaStyle.border,
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget
    event.preventDefault()
    if (form.checkValidity() === false) {
      event.stopPropagation()
      setValidated(true)
    } else if (recaptcha === false) {
      setRecaptchaStyle({ border: "1px solid red" })
      setTimeout(() => setRecaptchaStyle({ border: "none" }), 1000)
      event.stopPropagation()
    } else if (
      formData.fileUpload === "" ||
      file.attachment === "Max size: 2mb"
    ) {
      setFileKey(fileKey + 1)
      setFile({
        ...file,
        invalid: true,
        attachment: "You must upload your resume before submitting.",
      })
    } else {
      formPost()
      setFileKey(fileKey + 1)
      setFormKey(formKey + 1)
      setRecaptcha(false)
      setCheck(false)
      setFile({
        attachment: "Max size: 2mb",
        invalid: false,
      })
      setFormData({
        subject: "[Applicant] You have new form submission",
        name: "",
        email: "",
        contactNumber: "",
        fileUpload: "",
      })
      setValidated(false)
    }
  }

  const enableSubmit = () => {
    setRecaptcha(true)
  }

  const disableSubmit = () => {
    setRecaptcha(false)
  }

  const handleModal = (e) => {
    e.preventDefault()
    setShowTerms((prev) => !prev)
  }

  const handleChecked = () => {
    if (check) {
      setCheck(false)
    } else {
      setShowTerms(true)
    }
  }

  return (
    <>
      {show ? (
        <StyledForm
          name="Job Applications v1"
          data-netlify="true"
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleSubmit}
          data-netlify-honeypot="bot-field"
          noValidate
          validated={validated}
          modal={modal}
          scroll={showTerms ? "hidden" : "auto"}
          key={formKey}
        >
          {modal ? (
            <MdClose
              className="close-btn"
              size={30}
              onClick={() => setShowModal((prev) => !prev)}
            />
          ) : null}
          <Heading24>
            Drop your resume: <span>Quickly drop your resume</span>
          </Heading24>
          <input type="hidden" name="subject" value={formData.subject} />
          <Form.Group className="group">
            <Form.Label className="text-label">Full Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleNameChange}
              required
            />
          </Form.Group>
          <Form.Group className="group">
            <Form.Label className="text-label">Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="group">
            <Form.Label className="text-label">Phone No.</Form.Label>
            <Form.Control
              type="number"
              name="contactNumber"
              id="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="group checkbox-group">
            <Form.Label className="text-label hidden-label"></Form.Label>
            <Form.Check
              key={formKey}
              type="checkbox"
              id="checkbox"
              checked={check}
              onChange={handleChecked}
              required
              feedback="You must accept before submitting."
              feedbackType="invalid"
            />
            <span className="checkbox-label">
              I accept{" "}
              <span aria-hidden="true" onClick={handleModal}>
                Terms and Conditions
              </span>{" "}
              and{" "}
              <a href="/en/privacy-policy/" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              .
            </span>
          </Form.Group>
          <Form.Group className="group">
            <Form.Label className="text-label"></Form.Label>
            <Form.Group className="btn-group">
              <ReCAPTCHA
                className="recaptcha recaptcha-normal"
                sitekey="6LdMp5UiAAAAALNKVJYjyb_0gSwu_iV5JrH51zcG"
                onExpired={disableSubmit}
                onChange={enableSubmit}
                size="normal"
                style={reCaptchaStyle}
              />
              <ReCAPTCHA
                className="recaptcha recaptcha-compact"
                sitekey="6LdMp5UiAAAAALNKVJYjyb_0gSwu_iV5JrH51zcG"
                onExpired={disableSubmit}
                onChange={enableSubmit}
                size="compact"
                style={reCaptchaStyle}
              />
              <Form.Control
                key={fileKey}
                type="file"
                name="fileUpload"
                id="fileUpload"
                onChange={handleFileChange}
                accept=".doc,.docx,.pdf"
                hidden
              />
              <label
                className="upload-btn"
                htmlFor="fileUpload"
                style={{ background: "#149BE1" }}
              >
                Upload
              </label>
              <p
                className="fileTxt"
                style={{ color: file.invalid ? "red" : "#8F9496" }}
              >
                {file.attachment}
              </p>
              <Button className="btn" type="submit">
                Submit
              </Button>
            </Form.Group>
          </Form.Group>
          <TermsAndConditions
            show={showTerms}
            setShowModal={setShowTerms}
            setCheck={setCheck}
          />
          <FormNotification
            show={showNotification}
            setShow={setShowNotification}
          />
        </StyledForm>
      ) : null}
    </>
  )
}

export default JobApplicationForm
