import styled from "styled-components";
import { Row, Col, Container } from "react-bootstrap";

export const StyledContainer = styled(Container)`
  padding: 0 10% 100px 10%;
  position: relative;
  z-index: 1;

  h1 {
    text-align: center;
    margin-bottom: 50px;
  }

  .circle-bg {
    position: absolute;
    left: -696px;
    bottom: -200px;
    z-index: -1;
  }

  .circle-bg-medium {
    position: absolute;
    right: -416px;
    top: 250px;
    z-index: -1;
  }

  .professional-wrapper {
    padding-bottom: 100px;
  }

  @media screen and (max-width: 1000px) {
    .professional-wrapper {
      padding-bottom: 50px;
    }

    .professional-wrapper,
    .fresh-grad-wrapper {
      .career-desc-col {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media screen and (max-width: 769px) {
    padding: 0 5% 100px 5%;
  }

  @media screen and (max-width: 426px) {
    padding: 0 5% 50px 5%;
  }
`;

export const StyledRow = styled(Row)`
  display: flex;
  margin: 0;

  .img-col {
    height: 30vw;

    @media only screen and (max-width: 1000px) {
      height: 80vw;
      width: 100%;
    }
  }

  @media screen and (max-width: 1000px) {
    flex-direction: ${({ reversed }) =>
      reversed ? "column-reverse" : "column"};
    width: 100%;

    .professional-wrapper {
      padding-bottom: 50px;
    }
  }
`;

export const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-weight: bold;
    text-align: left;
  }

  h1,
  p {
    margin-bottom: 25px;
  }

  .professional-img {
    border-radius: 75px 0;
  }

  .fresh-grad-img {
    border-radius: 0 75px;
  }

  @media only screen and (max-width: 1000px) {
    padding: 0;
    .professional-img,
    .fresh-grad-img {
      margin-bottom: 25px;
    }
  }
`;

export const DropYourResume = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  

  .form-bg {
    margin-right: 50px;
    height: 100%;
    width: auto;
    aspect-ratio: square;
    object-fit: contain;
   

    img {
      width: 100%;
      height: 100%;
   

      border-radius: 20px;

      @media only screen and (max-width: 1440px) and (min-width: 1366px) {
        max-height: 450px;
      }

      @media only screen and (min-width: 2560px) {
        max-height: 1000px;
       
      }
    }
  }

  @media only screen and (max-width: 1365px) {
    flex-direction: column;
    
    .form-bg {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 426px) {
    h1 {
      text-align: left;
      margin-bottom: 25px;
    }
  }
`;
