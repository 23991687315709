import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Quote from "../../../static/svg/life-at-jfe-icon.svg"
import Slider from "react-slick"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  Heading24,
  Heading32,
  Paragraph16,
  Paragraph18,
} from "../../styles/PageStyles"

const Testimonial = () => {
  const carouselSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 750,
    pauseOnHover: false,
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const query = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "testimonials" } } }
        sort: { fields: frontmatter___date, order: ASC }
      ) {
        nodes {
          html
          frontmatter {
            title
            jobTitle
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      markdownRemark(frontmatter: { templateKey: { eq: "careers" } }) {
        frontmatter {
          testimonialsHeading
        }
      }
    }
  `)

  const testimonials = query.allMarkdownRemark.nodes
  const { testimonialsHeading } = query.markdownRemark.frontmatter

  return (
    <TestimonialWrapper>
      <Heading32>{testimonialsHeading}</Heading32>
      <StyledSlider {...carouselSettings}>
        {testimonials?.map((testimonial, index) => (
          <TestimonialCard key={index}>
            <Quote />
            <div className="testimonial-img">
              <GatsbyImage
                image={getImage(testimonial?.frontmatter?.image)}
                alt={testimonial?.frontmatter?.title}
              />
            </div>
            <div className="testimonial-desc">
              <div
                dangerouslySetInnerHTML={{
                  __html: testimonial?.html,
                }}
              />
              <Paragraph16 className="read-more">
                ...{" "}
                <span>
                  <Link to={`/life-at-jfe/#testimonial-${index + 1}`}>
                    read more
                  </Link>
                </span>
              </Paragraph16>
            </div>
            <div className="name">
              <Heading24>{testimonial?.frontmatter?.title}</Heading24>
            </div>
            <div className="job-position">
              <Paragraph18>{testimonial?.frontmatter?.jobTitle}</Paragraph18>
            </div>
          </TestimonialCard>
        ))}
      </StyledSlider>
    </TestimonialWrapper>
  )
}

export default Testimonial

const TestimonialWrapper = styled.div`
  margin: 100px 0;

  h1 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 769px) {
    margin: 50px 0;
  }
`

const TestimonialCard = styled.div`
  min-height: 530px;
  background: #fff;
  box-shadow: 1px 4px 15px 5px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 50px 25px;
  position: relative;
  display: flex !important;
  flex-direction: column;
  align-items: center;

  svg {
    position: absolute;
    top: 25px;
    left: 25px;
  }

  .testimonial-img {
    min-width: 105px;
    min-height: 105px;
    width: 105px;
    height: 105px;
    border-radius: 50%;
    overflow: hidden;
  }

  .testimonial-desc {
    position: relative;
    margin: 50px 0;
    max-height: 160px;

    p {
      text-align: justify;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .read-more {
      position: absolute;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(to right, transparent, #fff 30%, white);
      background-color: transparent;
      padding-left: 50px;
      font-family: Montserrat;
      font-weight: normal;
      text-align: justify;
      letter-spacing: 0.06em;
      color: #5b5d5e;

      span {
        a {
          font-family: Montserrat;
          font-weight: bold;
          text-align: justify;
          letter-spacing: 0.06em;
          color: #0073af;
        }
      }
    }
  }

  .name {
    h1 {
      margin: 0;
    }
  }

  .job-position {
    p {
      font-weight: bold;
      text-align: center;
      letter-spacing: 0.06em;
      color: #8f9496;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    height: unset;

    .testimonial-img {
      min-width: 80px;
      min-height: 80px;
      width: 80px;
      height: 80px;
    }

    .testimonial-desc {
      margin: 25px 0;

      p {
        font-size: 14px;
        -webkit-line-clamp: 4;
      }
    }
  }

  @media only screen and (max-width: 426px) {
    svg {
      width: 40px;
    }

    .testimonial-img {
      min-width: 90px;
      min-height: 90px;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
`

const StyledSlider = styled(Slider)`
  display: flex;
  align-items: center;
  padding: 0;
  z-index: 1;

  .slick-slide {
    margin-bottom: 35px;
    border-radius: 10px;
    padding: 12.5px;
  }

  .slick-dots {
    li button:before {
      display: none;
    }

    .slick-active {
      button {
        background: #0073af;
      }
    }

    button {
      width: 18px;
      height: 18px;
      background: #bcc1c3;
      border-radius: 50%;
    }
  }

  @media screen and (max-width: 769px) {
    margin: 0 0 50px 0;
    .slick-slide {
      padding: 0 10px;
    }
  }
`
