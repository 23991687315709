import styled from "styled-components"
import { Toast, Form } from "react-bootstrap"

export const StyledToast = styled(Toast)`
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 999;
  background: #fff;
  border: 1px solid #ffffff;

  .toast-header {
    background: #149be1;

    p {
      color: #ffffff;
      font-weight: bold;
    }
  }

  @media only screen and (max-width: 426px) {
    right: 50%;
    margin-right: -150px;
    width: 300px !important;
  }
`

export const StyledForm = styled(Form)`
  background: #ffffff;
  box-shadow: 0px 5px 15px 10px rgba(0, 0, 0, 0.03);
  border-radius: 20px;
  padding: 50px;
  width: 835px;
  position: ${({ modal }) => (modal ? "fixed" : "relative")};
  z-index: ${({ modal }) => (modal ? 999 : 1)};

  left: ${({ modal }) => (modal ? "50%" : null)};
  margin-left: ${({ modal }) => (modal ? "calc(-835px / 2)" : null)};
  top: ${({ modal }) => (modal ? "50%" : null)};
  margin-top: ${({ modal }) => (modal ? "-15%" : null)};

  .close-btn {
    fill: #575b5c;
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 25px;
  }

  h1 {
    text-align: left !important;
    margin-bottom: 25px !important;
    color: #0073af;

    span {
      font-size: 24px;
      line-height: 29px;
      color: #0073af;

      @media only screen and (max-width: 769px) {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .checkbox-group {
    @media only screen and (max-width: 500px) {
      height: 75px;
    }

    @media only screen and (max-width: 340px) {
      height: 85px;
    }
  }

  .group {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 15px;

    #checkbox {
      margin: 4px;
      border: 1px solid #8f9496;

      &:checked {
        background-color: #0073af;
        border-color: #0073af;
      }
    }

    .checkbox-label {
      width: 100%;
      margin-left: 5px;
      user-select: none;

      span {
        all: unset;
      }

      a,
      span {
        font-weight: bold;
        color: #0073af;
        cursor: pointer;
      }

      @media only screen and (max-width: 767px) {
        position: absolute;
        margin-left: 25px;
        margin-right: 50px;
        width: inherit;
      }
    }

    .invalid-feedback {
      position: absolute;
    }

    @media only screen and (max-width: 850px) {
      .invalid-feedback {
        margin-top: ${({ modal }) => (modal ? null : "30px")};
      }
    }

    @media only screen and (min-width: 768px) and (max-width: 812px) {
      .invalid-feedback {
        margin-top: ${({ modal }) => (modal ? "30px" : null)};
      }
    }

    @media only screen and (min-width: 756px) and (max-width: 768px) {
      .invalid-feedback {
        margin-top: ${({ modal }) => (modal ? null : "5px")};
      }
    }

    @media only screen and (min-width: 501px) and (max-width: 646px) {
      .btn-group {
        margin-top: 50px;
      }
    }

    @media only screen and (max-width: 646px) {
      .invalid-feedback {
        margin-top: ${({ modal }) => (modal ? "30px" : null)};
      }
    }

    @media only screen and (max-width: 500px) {
      .invalid-feedback {
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        left: 0;
        right: 0;
        text-align: center;
      }
    }

    @media only screen and (max-width: 340px) {
      .invalid-feedback {
        margin-top: 75px;
      }
    }

    @media only screen and (max-width: 293px) {
      .invalid-feedback {
        margin-top: 100px;
      }

      .btn-group {
        margin-top: 50px;
      }
    }
  }

  .text-label {
    margin: 0;
    padding-top: 9px;
    width: 200px;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.08em;
    color: #5b5d5e;
  }

  input,
  textarea {
    background: #f1f3f4;
    border-radius: 5px;
    border: none;
  }

  textarea {
    height: 110px;
  }

  .accept-checkbox {
    display: flex;
  }

  .recaptcha {
    float: left;
  }

  .recaptcha-normal {
    @media only screen and (max-width: 426px) {
      display: none !important;
    }
  }

  .recaptcha-compact {
    @media only screen and (min-width: 426px) {
      display: none !important;
    }
  }

  .btn,
  .upload-btn {
    max-width: max-content;
    height: max-content;
    background: #0073af;
    border-radius: 100px !important;
    border: none;
    padding: 5px 20px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    cursor: pointer;

    &:focus,
    &:active:hover {
      outline: none;
      box-shadow: none;
    }
  }

  .btn-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 25px;

    .fileTxt {
      width: 220px;
      height: max-content;
      position: absolute;
      top: 50px;
      right: 0;
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.06em;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    width: 770px;
    padding: 25px 50px;

    .text-label {
      width: 160px;
    }

    .text-label,
    .checkbox-label {
      font-size: 14px;
    }

    h1 {
      span {
        font-size: 18px;
      }
    }
  }

  @media only screen and (max-width: 1365px) {
    position: ${({ modal }) => (modal ? "fixed" : "relative")};
    width: ${({ modal }) => (modal ? "70%" : "100%")};
    top: ${({ modal }) => (modal ? "10%" : null)};
    left: ${({ modal }) => (modal ? "auto" : null)};
    right: ${({ modal }) => (modal ? "auto" : null)};
    margin: ${({ modal }) => (modal ? "0 5%" : null)};

    h1 {
      span {
        font-size: 20px;
      }
    }

    .text-label {
      font-size: 16px;
      line-height: 20px;
      font-style: normal;
      letter-spacing: 0.06em;
    }

    .fileTxt {
      width: 100% !important;
      top: 150px !important;
      right: 0 !important;
      text-align: right !important;
    }

    .recaptcha {
      float: left;
      display: flex;
      flex: 1 1 100%;
    }

    .btn-group {
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    .upload-btn,
    .btn {
      display: flex;
      flex: 1 1 100%;
      margin: 25px 15px 0 15px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 1200px) {
    width: ${({ modal }) => (modal ? "90%" : "100%")};
    left: ${({ modal }) => (modal ? "0" : null)};
    right: ${({ modal }) => (modal ? "0" : null)};
  }

  @media only screen and (max-width: 767px) {
    height: 80%;
    overflow-y: ${(props) => props.scroll};

    .group {
      flex-wrap: wrap;
      justify-content: flex-start;

      .hidden-label {
        display: none;
      }
    }

    .btn-group {
      justify-content: center;

      .recaptcha {
        justify-content: center;
      }

      .upload-btn,
      .btn {
        margin: 10px 15px;
      }

      .fileTxt {
        top: 150px !important;
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .btn-group {
      .fileTxt {
        top: 200px !important;
      }
    }
  }

  @media only screen and (max-width: 398px) {
    .btn-group {
      .fileTxt {
        top: 265px !important;
      }
    }
  }
`
